html,
body {
  height: 100%;
}

.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -60px;
  padding: 0 0 60px;
}

.wrap > .container {
  padding: 30px 15px 20px;
}

.footer {
  
}

.jumbotron {
  text-align: center;
  background-color: transparent;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

.not-set {
  color: #c55;
  font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}

a.asc:after {
  content: /*"\e113"*/ "\e151";
}

a.desc:after {
  content: /*"\e114"*/ "\e152";
}

.sort-numerical a.asc:after {
  content: "\e153";
}

.sort-numerical a.desc:after {
  content: "\e154";
}

.sort-ordinal a.asc:after {
  content: "\e155";
}

.sort-ordinal a.desc:after {
  content: "\e156";
}

.grid-view th {
  white-space: nowrap;
}

.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}

.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0;
}

.table-details {
  width: 20px;
  height: 20px;
  display: block;

  background: url('/images/table/details.png') no-repeat 0 0;
  cursor: pointer;
}

@import 'index';