.table-refresh {
  display: flex;
  align-items: center;
  padding-right: 100px;
  position: relative;

  .timer-refresh {
    position: absolute;
    left: 50px;
  }
}

.sub-menu {
  background-color: #e2e6e1;
  padding: 20px 25px;
  display: flex;
  align-items: center;

  .table-name {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
  }

  .table-description {
    display: block;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .sub-menu-title {
    margin-right: 30px;
  }
}

.custom-filter-wrap {
  position: relative;

  .custom-filter-grid {
    border: 0;
    border-radius: 5px;
    outline: 0;
    padding: 4px 10px;

    &::placeholder {
      opacity: .5;
      color: #252525;
      font-size: 16px;
    }
  }
  
  .custom-filter-label {
    color: #8f8f8f;
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 20px;
    display: block;
    position: absolute;
    bottom: 100%;
  }
}


.right-side {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.table-panel {
  display: flex;
  align-items: center;

  .table-panel-item {
    margin-left: 10px;

    &:first-child {
      margin-left: 30px;
    }
  }
}

.btn {
  border-radius: 4px;
  padding: 0 13px;
  font-size: 14px;
  line-height: 17px;
  border: 0;
  outline: 0;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: relative;
  min-height: 40px;
  white-space: normal;
  transition: 0.2s ease;

  &.btn-green {
    background-color: #56c131;
    color: #FFFFFF;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(86 193 49 / 28%);
    }
  }

  &.plus-btn {
    min-width: 40px;
    position: relative;

    &:after {
      content: '+';
      font-size: 26px;
      position: absolute;
      display: block;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      margin-top: -2px;
    }
  }
}