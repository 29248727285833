html,
body {
  height: 100%;
}
.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -60px;
  padding: 0 0 60px;
}
.wrap > .container {
  padding: 30px 15px 20px;
}
.jumbotron {
  text-align: center;
  background-color: transparent;
}
.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}
.not-set {
  color: #c55;
  font-style: italic;
}
/* add sorting icons to gridview sort links */
a.asc:after,
a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}
a.asc:after {
  content: "\e151";
}
a.desc:after {
  content: "\e152";
}
.sort-numerical a.asc:after {
  content: "\e153";
}
.sort-numerical a.desc:after {
  content: "\e154";
}
.sort-ordinal a.asc:after {
  content: "\e155";
}
.sort-ordinal a.desc:after {
  content: "\e156";
}
.grid-view th {
  white-space: nowrap;
}
.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}
.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0;
}
.table-details {
  width: 20px;
  height: 20px;
  display: block;
  background: url('/images/table/details.png') no-repeat 0 0;
  cursor: pointer;
}
.table-refresh {
  display: flex;
  align-items: center;
  padding-right: 100px;
  position: relative;
}
.table-refresh .timer-refresh {
  position: absolute;
  left: 50px;
}
.sub-menu {
  background-color: #e2e6e1;
  padding: 20px 25px;
  display: flex;
  align-items: center;
}
.sub-menu .table-name {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}
.sub-menu .table-description {
  display: block;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.sub-menu .sub-menu-title {
  margin-right: 30px;
}
.custom-filter-wrap {
  position: relative;
}
.custom-filter-wrap .custom-filter-grid {
  border: 0;
  border-radius: 5px;
  outline: 0;
  padding: 4px 10px;
}
.custom-filter-wrap .custom-filter-grid::placeholder {
  opacity: 0.5;
  color: #252525;
  font-size: 16px;
}
.custom-filter-wrap .custom-filter-label {
  color: #8f8f8f;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  display: block;
  position: absolute;
  bottom: 100%;
}
.right-side {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.table-panel {
  display: flex;
  align-items: center;
}
.table-panel .table-panel-item {
  margin-left: 10px;
}
.table-panel .table-panel-item:first-child {
  margin-left: 30px;
}
.btn {
  border-radius: 4px;
  padding: 0 13px;
  font-size: 14px;
  line-height: 17px;
  border: 0;
  outline: 0;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: relative;
  min-height: 40px;
  white-space: normal;
  transition: 0.2s ease;
}
.btn.btn-green {
  background-color: #56c131;
  color: #FFFFFF;
}
.btn.btn-green:focus {
  box-shadow: 0 0 0 0.25rem rgba(86, 193, 49, 0.28);
}
.btn.plus-btn {
  min-width: 40px;
  position: relative;
}
.btn.plus-btn:after {
  content: '+';
  font-size: 26px;
  position: absolute;
  display: block;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  margin-top: -2px;
}
